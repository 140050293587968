import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navbar, Nav, Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { FaMoneyBillAlt } from 'react-icons/fa';
import Footer from '../components/Footer';
import Swal from 'sweetalert2';
import Loading from '../components/Loading';
import axios from 'axios';

const DataPage = () => {
  const navigate = useNavigate();
  const [networkName, setNetworkName] = useState('');
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [airtimePercentage, setAirtimePercentage] = useState(null);
  const [airtimeAmount, setAirtimeAmount] = useState(''); // State for user-input amount
  const [discountAmount, setDiscountAmount] = useState('0.00'); // Initial discount is 0.00
  const [availablePlanTypes, setAvailablePlanTypes] = useState([]); // State to track available plan types

  useEffect(() => {
    const token = localStorage.getItem('userToken');

    if (!token) {
      navigate('/');
    } else {
      validateToken(token);
      fetchAirtimePercentage(); // Fetch percentage on component mount
    }
  }, [navigate]);

  const networkPrefixes = {
    MTN: ['0703', '0706', '0803', '0806', '0810', '0813', '0814', '0816', '0903', '0906', '0913'],
    Glo: ['0705', '0805', '0807', '0811', '0815', '0905'],
    Airtel: ['0701', '0708', '0702', '0704', '0802', '0808', '0812', '0901', '0902', '0904', '0907', '0912'],
    '9mobile': ['0809', '0817', '0818', '0909', '0908'],
  };

  const checkPhoneNumberNetwork = (phoneNumber) => {
    const numberPrefix = phoneNumber.substr(0, 4);
    let detectedNetwork = '';

    Object.entries(networkPrefixes).forEach(([networkName, prefixes]) => {
      if (prefixes.includes(numberPrefix)) {
        detectedNetwork = networkName;
      }
    });

    if (detectedNetwork) {
      setNetworkName(detectedNetwork);
      setFormError('');
    } else {
      setNetworkName('');
      setFormError('');
    }
  };

  const handlePhoneNumberChange = (event) => {
    const phoneNumber = event.target.value;
    if (phoneNumber.length >= 4) {
      checkPhoneNumberNetwork(phoneNumber);
    } else {
      setNetworkName('');
      setFormError('');
    }
  };

  const handleAirtimeAmountChange = (event) => {
    const amount = parseFloat(event.target.value); // Parse to number
    setAirtimeAmount(amount);
  
    if (airtimePercentage && amount) {
      const discountAmount = (amount * (airtimePercentage / 100)); // Calculate discount
      const payableAmount = amount - discountAmount; // Calculate payable amount
      setDiscountAmount(payableAmount.toFixed(2)); // Update discount amount state
    } else {
      setDiscountAmount('0.00');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
  
    const network = formData.get('network');
    const phoneNumber = formData.get('phoneNumber');
    const transactionPin = formData.get('transactionPin');
    const airtimeAmount = formData.get('airtimeAmount');
    const bypassValidation = formData.get('bypassValidation');
  
    if (!network || !phoneNumber || !transactionPin || !airtimeAmount) {
      setFormError('Please fill in all required fields.');
      return;
    }
  
    if (transactionPin !== data.pin) {
      Swal.fire({
        icon: 'error',
        title: 'Failed!',
        text: 'Invalid transaction pin',
      });
      return;
    }
  
    setLoading(true);
  
    try {
      const domain = window.location.hostname;
      const token = localStorage.getItem('userToken');
  
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/app/buyAirtime`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          network,
          phoneNumber,
          transactionPin,
          airtimeAmount,
          bypassValidation,
          domain,
        }),
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        Swal.fire({
          icon: 'error',
          title: 'Failed!',
          text: data.message || 'Error placing order, please try again',
        });
        return;
      }
  
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: data.message,
      }).then(() => {
        refreshUserInfo();
      });
  
    } catch (error) {
      console.error('Error placing order:', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed!',
        text: 'Error placing order, please try again',
      });
    } finally {
      setLoading(false);
    }
  };
  

  const validateToken = async (token) => {
    const domain = window.location.hostname;

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/app/userInfo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Add Authorization header
        },
        body: JSON.stringify({ token, domain }),
      });

      if (!response.ok) {
        throw new Error('Failed to validate token');
      }

      const responseData = await response.json();

      if (responseData.status === 'failed') {
        localStorage.removeItem('userToken');
        navigate('/');
      } else {
        setData(responseData.user); // Set user data to state
        setIsLoading(false);
      }
    } catch (error) {
      localStorage.removeItem('userToken');
      navigate('/'); // Redirect to login on error
    }
  };

  const handleBackClick = () => {
    navigate('/dashboard');
  };

  const handleCardClick = (path) => {
    navigate(path);
  };
  





  const fetchAirtimePercentage = async () => {
    const domain = window.location.hostname;
    const url = `${process.env.REACT_APP_BACKEND_URL}/site/info`;

    try {
      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Domain': domain
        }
      });

      if (response.data.siteName) {
        setAirtimePercentage(response.data.airtimePercentage);
      }
    } catch (error) {
      console.error('Error fetching site info:', error);
    }
  };


  const refreshUserInfo = async () => {
    const token = localStorage.getItem('userToken');
    if (!token) return;

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/app/userInfo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Add Authorization header
        },
        body: JSON.stringify({ token, domain: window.location.hostname }),
      });

      if (!response.ok) {
        throw new Error('Failed to refresh user info');
      }

      const responseData = await response.json();

      if (responseData.status === 'success') {
        setUserData(responseData.user);
        setData(responseData.user);
      }

    } catch (error) {
      console.error('Error refreshing user info:', error);
    }
  };
  
  if (isLoading) {
    return <Loading />;
  }

  return (
    <div style={{ backgroundColor: '#F9FAFB', minHeight: '100vh' }}>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand onClick={handleBackClick} style={{ cursor: 'pointer' }}>
            {'Back'}
          </Navbar.Brand>
          <Nav className="ml-auto">
            <Nav.Link>Buy Airtime</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <Container className="mt-4">
        <Row className="justify-content-center">
          <Col md={7}>
            <Card className="balance-card mb-4">
              <Card.Body className="d-flex align-items-center">
                <FaMoneyBillAlt className="menu-icon" />
                <div className="leftshift">
                  <h5>Wallet Balance</h5>
                  <div><b>₦{data && data.wallet && data.wallet.toLocaleString()}</b></div>
                </div>
              </Card.Body>
            </Card>

            <Card style={{ backgroundColor: 'white', borderRadius: '10px', border: 'none' }}>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="formNetwork">
                    <Form.Label>Network</Form.Label>
                    <Form.Control as="select" name="network" required>
                      <option value="">Select Network</option>
                      {Object.keys(networkPrefixes).map((network) => (
                        <option key={network} value={network}>
                          {network}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="formPhoneNumber">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="number"
                      name="phoneNumber"
                      placeholder="Enter phone number"
                      maxLength={11}
                      onChange={handlePhoneNumberChange}
                      required
                    />
                 {networkName && (
  <div style={{ color: 'green', marginBottom: '10px' }}>
    <b>{networkName}</b> number detected.
  </div>
)}
                  </Form.Group>

                  <Form.Group controlId="formAirtimeAmount">
                    <Form.Label>Airtime Amount</Form.Label>
                    <Form.Control
                      type="number"
                      name="airtimeAmount"
                      placeholder="Enter amount"
                      maxLength={3}
                      required
                      onChange={handleAirtimeAmountChange}
                    />
                  </Form.Group>

                  {discountAmount && (
                    <Form.Group controlId="discount">
                      <Form.Label>Discount</Form.Label>
                      <Form.Control
                        type="text"
                        name="discount"
                        value={`₦${discountAmount}`}
                        readOnly
                      />
                    </Form.Group>
                  )}

                  <Form.Group controlId="formTransactionPin">
                    <Form.Label>Transaction Pin</Form.Label>
                    <Form.Control type="password" name="transactionPin" placeholder="****" required />
                  </Form.Group><br />

                  <Button type="submit" variant="primary" disabled={loading}>
                    {loading ? 'Placing Order...' : 'Place Order'}
                  </Button>

                  {formError && <div style={{ color: 'red', marginTop: '10px' }}>{formError}</div>}
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default DataPage;
  