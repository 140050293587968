import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import axios from 'axios';

const Loading = () => {
  const [backgroundColor, setBackgroundColor] = useState('');
  const [textColor, setTextColor] = useState(''); 
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSiteInfo = async () => {
      const domain = window.location.hostname;
      const url = `${process.env.REACT_APP_BACKEND_URL}/site/info`;

      try {
        const response = await axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
            'Domain': domain,
          },
        });

        if (response.data.backgroundColor) {
          setBackgroundColor(response.data.backgroundColor);
          setTextColor(response.data.textColor);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching site info:', error);
      }
    };

    fetchSiteInfo();
  }, []);

  return (
    <div style={{ minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: backgroundColor }}>
      <Spinner
        animation="border"
        variant="dark"
        role="status"
        style={{ color: '#red', height: '60px', width: '60px' }} // Smaller size
      >
        <span className="sr-only">..</span> 
      </Spinner>
    </div>
  );
};

export default Loading;