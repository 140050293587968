import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Navbar, Nav, Container, Form, Button, Card, Row, Col, Modal, Spinner } from 'react-bootstrap';
import { FaWhatsapp, FaSignInAlt, FaUserPlus, FaBolt, FaTv, FaMobileAlt, FaWifi, FaSms, FaShieldAlt, FaFastForward, FaRobot } from 'react-icons/fa';
import axios from 'axios';
import Footer from '../src/components/Footer';
import Loading from '../src/components/Loading';
import Swal from 'sweetalert2';
import { useRef } from 'react'; 

function App() {
  const [expanded, setExpanded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('signIn');
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showTransactionPin, setShowTransactionPin] = useState(false);
  const [siteName, setSiteName] = useState('');
  const [websiteSlogan, setwebsiteSlogan] = useState('');
  const [siteLogo, setsiteLogo] = useState('');
  const [siteAddress, setsiteAddress] = useState('');
  const [siteEmail, setsiteEmail] = useState('');
  const [sitePhone, setsitePhone] = useState('');
  const [aboutUs, setaboutUs] = useState('');
  const [heroImage, setheroImage] = useState('');
  const [aboutUsImage, setaboutUsImage] = useState('');
  const [aboutImage, setaboutImage] = useState('');
  const [homePagePriceDisplay, sethomePagePriceDisplaye] = useState('');
  const [textColor, settextColor] = useState('');
  const [backgroundColor, setbackgroundColor] = useState('');
  const [whatsappNumber, setwhatsappNumber] = useState('');
  const [pricingPlans, setPricingPlans] = useState([]);
  const [referredBy, setReferredBy] = useState('');
  const [siteInfo, setSiteInfo] = useState(null);


  useEffect(() => {
    const hostname = window.location.hostname;
    if (hostname.startsWith('www.')) {
      const newHost = hostname.replace('www.', '');
      window.location.replace(`https://${newHost}`); 
    }
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const refValue = urlParams.get('ref');

    if (refValue) {
      setReferredBy(refValue);
    }
  }, []);

  useEffect(() => {
    const fetchDataPlans = async () => {
      const domain = window.location.hostname;
      const url = `${process.env.REACT_APP_BACKEND_URL}/app/dataPlan`; 

      try {
        const response = await axios.post(url, {}, { 
          headers: {
            'Content-Type': 'application/json',
            'Domain': domain 
          }
        });

        if (response.data.status === 'success') {
          const dataPlans = response.data.filteredDataPlans.map(plan => ({
            title: `${plan.network} ${plan.planType}`,
            price: `₦${plan.Affiliate} / ${plan.dataSize}${plan.sizeType}`,
            validity: plan.validity,
            network: plan.network
          }));
          setPricingPlans(dataPlans);
          setIsLoading(false);

        } else {
          console.error('Error fetching data plans:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data plans:', error);
      }
    };

    fetchDataPlans();
  }, []);








  useEffect(() => {
    const fetchSiteInfo = async () => {
      const domain = window.location.hostname;
      const url = `${process.env.REACT_APP_BACKEND_URL}/site/info`;

      try {
        const response = await axios.post(url, {}, { 
          headers: {
            'Content-Type': 'application/json',
            'Domain': domain 
          }
        });

        if (response.data.siteName) {
          setSiteName(response.data.siteName);
          setwebsiteSlogan(response.data.websiteSlogan);
          setsiteAddress(response.data.address);
          setsiteEmail(response.data.siteEmail);
          setsitePhone(response.data.phoneNumber);
          setsiteLogo(response.data.siteLogo);      
          setaboutUs(response.data.aboutUs);
          setheroImage(response.data.heroImage);
          setaboutUsImage(response.data.aboutImage);
          settextColor(response.data.textColor);
          setbackgroundColor(response.data.backgroundColor);
          setwhatsappNumber(response.data.whatsappNumber);
          setaboutImage(response.data.aboutImage);
          sethomePagePriceDisplaye(response.data.homePagePriceDisplay);
        }
        setIsLoading(false);

      } catch (error) {
        console.error('Error fetching site info:', error);
      }
    };

    fetchSiteInfo();
  }, []);




  function MetaTags() {
    // Use the existing state variables directly
    return (
      <Helmet>
        <title>{siteName} - {websiteSlogan}</title>
        <meta name="description" content={aboutUs} />
        <meta property="og:title" content={`${siteName} - ${websiteSlogan}`} />
        <meta property="og:description" content={aboutUs} />
        <meta property="og:image" content={heroImage} />
        <meta property="og:url" content={`https://${window.location.hostname}`} /> 
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={siteName} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${siteName} - ${websiteSlogan}`} />
        <meta name="twitter:description" content={aboutUs} />
        <meta name="twitter:image" content={heroImage} />
        <meta name="twitter:url" content={`https://${window.location.hostname}`} /> 
      </Helmet>
    );
  }




  useEffect(() => {
    const verifyEmailFromURL = async () => {
      const params = new URLSearchParams(window.location.search);
      const token = params.get('verify');
      const domain = window.location.hostname;

      if (token) {
        setLoading(true); 
        await verifyEmail(token, domain);
        setLoading(false); 
      }
    };

    verifyEmailFromURL();
  }, []);

  const verifyEmail = async (token, domain) => {
    try {
      const verifurl = `${process.env.REACT_APP_BACKEND_URL}/app/emailVerification`;
    
      const response = await axios.post(verifurl, { token, domain });
      
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Email Verified',
          text: 'Your email has been successfully verified. You can now log in.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Verification Failed',
          text: response.data.message || 'There was an issue with the verification.',
        });
      }
    } catch (error) {
      console.error('Verification failed:', error.response || error.message);
      
      Swal.fire({
        icon: 'error',
        title: 'Invalid Verification Link',
        text: 'The verification link is invalid or has expired.',
      });
    }
  };
  

  const handleNavClick = () => setExpanded(false);

  const handleShowModal = (type) => {
    setModalType(type);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    clearMessages();
  };

  const handleShowForgotPasswordModal = () => {
    setShowModal(false);
    setTimeout(() => setShowForgotPasswordModal(true), 600);
  };

  const handleCloseForgotPasswordModal = () => {
    setShowForgotPasswordModal(false);
    clearMessages();
  };

  const handleSubmit = async (e, type) => {
    e.preventDefault();
    setLoading(true);
    setResponseMessage('');
    setErrorMessage('');

    const form = e.target;
    const data = new FormData(form);
    const payload = Object.fromEntries(data.entries());
    payload.domain = window.location.hostname;

    const url = `${process.env.REACT_APP_BACKEND_URL}/api/auth/${type}`;
    try {
      const response = await axios.post(url, payload);
      setResponseMessage(response.data.message);

      if (type === 'login') {
        const token = response.data.token;
        localStorage.setItem('userToken', token);
        window.location.href = '/dashboard';
        return;
      }

      if (type === 'forgot-password') {
        return;
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Something went wrong!');
    } finally {
      setLoading(false);
      setTimeout(() => {
        clearMessages();
      }, 10000);
    }
  };

  const clearMessages = () => {
    setResponseMessage('');
    setErrorMessage('');
  };


  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
  <MetaTags />
      <Navbar expand="lg" expanded={expanded} style={{boxShadow: '0 4px 8px rgba(0,0,0,0.1)', backgroundColor: `${backgroundColor}`}}>
        <Container>
        <Navbar.Brand href="/" style={{ fontWeight: 'bold', fontSize: '24px', color: `${textColor}`}}>
  {siteLogo ? <img src={siteLogo} alt="Logo" style={{ maxHeight: '30px', marginRight: '10px' }} /> : siteName}
</Navbar.Brand>


<Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
<Navbar.Collapse id="basic-navbar-nav" >
<Nav className="ml-auto" style={{fontSize: '17px'}}>
  <Nav.Link href="#about" onClick={handleNavClick} style={{color: `${textColor}`}}>About Us</Nav.Link>
  <Nav.Link href="#features" onClick={handleNavClick} style={{color: `${textColor}`}}>Features</Nav.Link>
  {homePagePriceDisplay === 1 && (
  <Nav.Link href="#pricing" onClick={handleNavClick} style={{color: `${textColor}`}}>Pricing</Nav.Link>
)}

  <Nav.Link href="#services" onClick={handleNavClick} style={{color: `${textColor}`}}>Services</Nav.Link>
  <Nav.Link href="#contact" onClick={handleNavClick} style={{color: `${textColor}`}}>Contact Us</Nav.Link>
  <Nav.Link className="nav-button" onClick={() => handleShowModal('signIn')}>
    <Button variant="outline-primary" className="mr-2" style={{borderRadius: '20px', color: `${textColor}`}}><FaSignInAlt /> Sign In</Button>
  </Nav.Link>
  <Nav.Link className="nav-button" onClick={() => handleShowModal('signUp')}>
    <Button variant="outline-success" style={{borderRadius: '20px',  color: `${textColor}`}} ><FaUserPlus /> Sign Up</Button>
  </Nav.Link>
</Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>






<div 
  className="hero-section" 
  style={{ 
    backgroundImage: `url('${heroImage}')`, 
    backgroundSize: 'cover', 
    backgroundPosition: 'center', 
    height: '500px', 
    width: '100vw', 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    color: '#fff', 
    textAlign: 'center',
    margin: '0', 
    padding: '0' 
  }}>
  
  <Container style={{ textAlign: 'center' }}>
    Welcome to<br />
    <h1 style={{
      fontSize: '34px', 
      fontWeight: 'bold', 
      color: `#FFF`, 
      textShadow: `2px 2px 6px #000, -2px -2px 6px #000`
    }}>
      {siteName}
    </h1>

    <p style={{
      fontSize: '23px', 
      lineHeight: '1.5',  
      color: '#fff',   
      textShadow: `2px 2px 10px #000, -2px -2px 10px #000`
    }}>
      Discover our amazing services and features. We provide solutions that help businesses grow.
    </p>

    <div style={{marginTop: '20px'}}>
      <Button 
        variant="primary" 
        onClick={() => handleShowModal('signIn')} 
        style={{
          backgroundColor: `${backgroundColor}`, 
          border: 'none', 
          padding: '15px 30px', 
          borderRadius: '20px', 
          marginRight: '10px', 
          fontSize: '18px', 
          color: `${textColor}`
        }}>
        <FaSignInAlt /> Sign In
      </Button>

      <Button 
        variant="secondary" 
        onClick={() => handleShowModal('signUp')} 
        style={{
          backgroundColor: `${backgroundColor}`, 
          border: 'none', 
          padding: '15px 30px', 
          borderRadius: '20px', 
          fontSize: '18px', 
          color: `${textColor}`
        }}>
        <FaUserPlus /> Sign Up
      </Button>
    </div>
  </Container>
</div>





      <div className="about-section" id="about" style={{ padding: '50px 0' }}>
        <Container>
          <Row>
<Col md={6} style={{marginBottom: '30px'}}>
<img src={aboutUsImage} alt="About Us" className="img-fluid" style={{ borderRadius: '10px' }} />
</Col>
<Col md={6}>
  <h2 style={{fontSize: '36px', fontWeight: 'bold', marginBottom: '20px', color: `${backgroundColor}`}}>About Us</h2>
  <p style={{fontSize: '18px', lineHeight: '1.5'}}>{aboutUs}</p>
</Col>
          </Row>
        </Container>
      </div>

      <div className="features-section" id="features" style={{ padding: '50px 0', backgroundColor: `${backgroundColor}`, color: `${textColor}` }}>
        <Container>
          <h2 style={{fontSize: '36px', fontWeight: 'bold', marginBottom: '30px'}}>Features</h2>
          <Row>
<Col md={4} style={{marginBottom: '30px'}}>
  <h3 style={{fontSize: '24px', fontWeight: 'bold', marginBottom: '15px'}}><FaShieldAlt /> Reliability</h3>
  <p style={{fontSize: '18px', lineHeight: '1.5'}}>At {siteName}, we prioritize our customers and consistently deliver on our promises to ensure their satisfaction.</p>
</Col>
<Col md={4} style={{marginBottom: '30px'}}>
  <h3 style={{fontSize: '24px', fontWeight: 'bold', marginBottom: '15px'}}><FaFastForward /> Speed</h3>
  <p style={{fontSize: '18px', lineHeight: '1.5'}}>We aim to make our platform not only customer-centric but also incredibly efficient. Experience lightning-fast delivery times with us.</p>
</Col>
<Col md={4} style={{marginBottom: '30px'}}>
  <h3 style={{fontSize: '24px', fontWeight: 'bold', marginBottom: '15px'}}><FaRobot /> Automation</h3>
  <p style={{fontSize: '18px', lineHeight: '1.5'}}>Our data delivery and wallet funding are automated, with airtime top-ups and data purchases processed and delivered to you almost instantly.</p>
</Col>
          </Row>
        </Container>
      </div>



      {homePagePriceDisplay === 1 && (
  <div className="pricing-section" id="pricing" style={{ padding: '50px 0' }}>
    <Container>
      <h2 style={{ fontSize: '36px', fontWeight: 'bold', marginBottom: '30px', color: backgroundColor }}>Pricing</h2>
      <Row>
        {pricingPlans.map((plan, index) => {
          let bgColor, textColor, buttonBgColor, buttonTextColor;
          switch (plan.network) {
            case 'MTN':
              bgColor = '#F7C400';
              textColor = '#000';
              buttonBgColor = '#e0ab00';
              buttonTextColor = '#000';
              break;
            case 'AIRTEL':
              bgColor = '#E81B22';
              textColor = '#fff';
              buttonBgColor = '#c4161d';
              buttonTextColor = '#fff';
              break;
            case 'GLO':
              bgColor = '#268439';
              textColor = '#fff';
              buttonBgColor = '#1f6e2f';
              buttonTextColor = '#fff';
              break;
            case '9MOBILE':
              bgColor = '#000';
              textColor = '#fff';
              buttonBgColor = '#333';
              buttonTextColor = '#fff';
              break;
            default:
              bgColor = '#fff';
              textColor = '#000';
              buttonBgColor = '#ddd';
              buttonTextColor = '#000';
              break;
          }

          return (
            <Col key={index} md={4} sm={6} xs={6} style={{ marginBottom: '10px' }}>
              <Card style={{ boxShadow: '0 4px 8px rgba(0,0,0,0.1)', borderRadius: '10px', backgroundColor: bgColor }}>
                <Card.Body style={{ color: textColor }}>
                  <center>
                    <Card.Title style={{ fontSize: '18px', fontWeight: 'bold' }}>{plan.title}</Card.Title>
                    <Card.Text style={{ fontSize: '17px', lineHeight: '1.0' }}>{plan.price}<br /><small>Valid for {plan.validity}</small></Card.Text>
                    <Nav.Link className="nav-button" onClick={() => handleShowModal('signUp')}>
                      <Button
                        variant="primary"
                        style={{ border: 'none', padding: '5px 10px', borderRadius: '20px', fontSize: '12px', backgroundColor: buttonBgColor, color: buttonTextColor }}
                      >
                        Get Started 
                      </Button>
                    </Nav.Link>
                  </center>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </Container>
  </div>
)}














      <div className="services-section" id="services" style={{ padding: '50px 0', backgroundColor: '#000', color: '#fff' }}>
        <Container>
          <h2 style={{fontSize: '36px', fontWeight: 'bold', marginBottom: '30px'}}>Services</h2>
          <Row>
<Col md={4} style={{marginBottom: '30px'}}>
  <h3 style={{fontSize: '24px', fontWeight: 'bold', marginBottom: '15px'}}><FaWifi /> Data Bundle</h3>
</Col>

<Col md={4} style={{marginBottom: '30px'}}>
  <h3 style={{fontSize: '24px', fontWeight: 'bold', marginBottom: '15px'}}><FaTv /> Cable TV</h3>
</Col>

<Col md={4} style={{marginBottom: '30px'}}>
  <h3 style={{fontSize: '24px', fontWeight: 'bold', marginBottom: '15px'}}><FaMobileAlt /> Airtime</h3>
</Col>
<Col md={4} style={{marginBottom: '30px'}}>
  <h3 style={{fontSize: '24px', fontWeight: 'bold', marginBottom: '15px'}}><FaBolt /> Utility</h3>
</Col>
<Col md={4} style={{marginBottom: '30px'}}>
  <h3 style={{fontSize: '24px', fontWeight: 'bold', marginBottom: '15px'}}><FaSms /> SMS</h3>
</Col>
<Col md={4} style={{marginBottom: '30px'}}>
  <h3 style={{fontSize: '24px', fontWeight: 'bold', marginBottom: '15px'}}><FaSms /> Result Checker</h3>
</Col>
          </Row>
        </Container>
      </div>





      <div className="contact-section" id="contact" style={{ padding: '50px 0' }}>
        <Container>
          <h2 style={{fontSize: '30px', fontWeight: 'bold', marginBottom: '5px', color: `${backgroundColor}`}}>Contact Us</h2>
          <Row>
<Col md={6} style={{marginBottom: '4px'}}>
<center style={{ marginBottom: '1px', color: `${backgroundColor}`, fontWeight: 'bold' }}>
  {siteLogo ? (
    <img
      src={siteLogo}
      alt="Site Logo"
      style={{ maxHeight: '300px', width: '100%' }}
    />
  ) : (
    siteName
  )}
</center>

</Col>
<Col md={5} px={10}>
  <h3 style={{fontSize: '24px', fontWeight: 'bold', marginBottom: '15px'}}>Our Office</h3>
  <p style={{fontSize: '18px', lineHeight: '1.5'}}>{siteAddress}</p>
  <p style={{fontSize: '18px', lineHeight: '1.5'}}>Email: {siteEmail}</p>
  <p style={{fontSize: '18px', lineHeight: '1.5'}}>Phone: {sitePhone}</p>
</Col>
          </Row>
        </Container>
      </div>





      <Modal show={showModal} onHide={handleCloseModal} size="sm" centered style={{borderRadius: '10px'}}>
        <Modal.Header closeButton style={{backgroundColor: `${backgroundColor}`, color: `${textColor}`}}>
          <Modal.Title style={{fontSize: '20px', fontWeight: 'bold', backgroundColor: `${backgroundColor}`, color: `${textColor}`}}>{modalType === 'signIn' ? 'Sign In' : 'Sign Up'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalType === 'signIn' ? (
<Form onSubmit={(e) => handleSubmit(e, 'login')}>
  <Form.Group controlId="formEmail">
    <Form.Label style={{fontSize: '16px', fontWeight: 'bold'}}>Email</Form.Label>
    <Form.Control type="email" name="email" placeholder="Enter your email" required style={{padding: '10px', borderRadius: '5px'}} />
  </Form.Group>
  <Form.Group controlId="formPassword">
    <Form.Label style={{fontSize: '16px', fontWeight: 'bold'}}>Password</Form.Label>
    <Form.Control type={showPassword ? 'text' : 'password'} name="loginpassword" placeholder="Enter your password" required style={{padding: '10px', borderRadius: '5px'}} />
    <Button variant="link" onClick={() => setShowPassword(!showPassword)} style={{fontSize: '14px', color: `${backgroundColor}`}}>
      {showPassword ? 'Hide' : 'Show'}
    </Button>
  </Form.Group>
  <Button variant="primary" type="submit" disabled={loading} style={{backgroundColor: `${backgroundColor}`, color: `${textColor}`, border: 'none', padding: '10px 20px', borderRadius: '20px', fontSize: '16px', width: '100%'}}>
    {loading ? 'Loading...' : 'Sign In'}
  </Button>
  {responseMessage && <p className="text-success mt-2" style={{fontSize: '14px'}}>{responseMessage}</p>}
  {errorMessage && <p className="text-danger mt-2" style={{fontSize: '14px'}}>{errorMessage}</p>}
</Form>
          ) : (
<Form onSubmit={(e) => handleSubmit(e, 'register')}>
  <Form.Group controlId="formFullName">
    <Form.Label style={{fontSize: '16px', fontWeight: 'bold'}}>Full Name</Form.Label>
    <Form.Control name="fullName" type="text" placeholder="Enter full name" required style={{padding: '10px', borderRadius: '5px'}} />
  </Form.Group>
  <Form.Group controlId="formEmail">
    <Form.Label style={{fontSize: '16px', fontWeight: 'bold'}}>Email</Form.Label>
    <Form.Control type="email" name="email" placeholder="Enter email" required style={{padding: '10px', borderRadius: '5px'}} />
  </Form.Group>
  <Form.Group controlId="formPhoneNumber">
    <Form.Label style={{fontSize: '16px', fontWeight: 'bold'}}>Phone Number</Form.Label>
    <Form.Control type="number" name="phoneNumber" placeholder="Enter phone number" maxLength="11" minLength="11" required style={{padding: '10px', borderRadius: '5px'}} />
  </Form.Group>


  <Form.Group controlId="formReferredBy">
                <Form.Label style={{fontSize: '16px', fontWeight: 'bold'}}>Referred By</Form.Label>
                <Form.Control 
                  type="text" 
                  name="referredBy" 
                  placeholder="Ref Code" 
                  style={{padding: '10px', borderRadius: '5px'}} 
                  value={referredBy}
                  readOnly={!!referredBy} // Make it read-only if referredBy has a value
                />
              </Form.Group>


  <Form.Group controlId="formPassword">
    <Form.Label style={{fontSize: '16px', fontWeight: 'bold'}}>Password</Form.Label>
    <Form.Control type={showPassword ? 'text' : 'password'} name="password" placeholder="Enter your password" required style={{padding: '10px', borderRadius: '5px'}} />
  </Form.Group>
  <Button variant="link" onClick={() => setShowPassword(!showPassword)} style={{fontSize: '14px', color: `${backgroundColor}`}}>
    {showPassword ? 'Hide' : 'Show'}
  </Button>
  <Form.Group controlId="formTransactionPin">
    <Form.Label style={{fontSize: '16px', fontWeight: 'bold'}}>Transaction Pin</Form.Label>
    <Form.Control type={showTransactionPin ? 'text' : 'password'} name="transactionPin" placeholder="Enter 4 digits transaction pin" required style={{padding: '10px', borderRadius: '5px'}} />
  </Form.Group>
  <Button variant="link" onClick={() => setShowTransactionPin(!showTransactionPin)} style={{fontSize: '14px', color: `${backgroundColor}`}}>
    {showTransactionPin ? 'Hide' : 'Show'}
  </Button>





  <Form.Group controlId="formlocation">
  <Form.Label style={{fontSize: '16px', fontWeight: 'bold'}}>Location</Form.Label>
  <Form.Control as="select" name="location" required style={{padding: '10px', borderRadius: '5px'}}>
    <option value="Abia">Abia</option>
    <option value="Adamawa">Adamawa</option>
    <option value="Akwa Ibom">Akwa Ibom</option>
    <option value="Anambra">Anambra</option>
    <option value="Bauchi">Bauchi</option>
    <option value="Bayelsa">Bayelsa</option>
    <option value="Benue">Benue</option>
    <option value="Borno">Borno</option>
    <option value="Cross River">Cross River</option>
    <option value="Delta">Delta</option>
    <option value="Ebonyi">Ebonyi</option>
    <option value="Edo">Edo</option>
    <option value="Ekiti">Ekiti</option>
    <option value="Enugu">Enugu</option>
    <option value="Gombe">Gombe</option>
    <option value="Imo">Imo</option>
    <option value="Jigawa">Jigawa</option>
    <option value="Kaduna">Kaduna</option>
    <option value="Kano">Kano</option>
    <option value="Katsina">Katsina</option>
    <option value="Kebbi">Kebbi</option>
    <option value="Kogi">Kogi</option>
    <option value="Kwara">Kwara</option>
    <option value="Lagos">Lagos</option>
    <option value="Nasarawa">Nasarawa</option>
    <option value="Niger">Niger</option>
    <option value="Ogun">Ogun</option>
    <option value="Ondo">Ondo</option>
    <option value="Osun">Osun</option>
    <option value="Oyo">Oyo</option>
    <option value="Plateau">Plateau</option>
    <option value="Rivers">Rivers</option>
    <option value="Sokoto">Sokoto</option>
    <option value="Taraba">Taraba</option>
    <option value="Yobe">Yobe</option>
    <option value="Zamfara">Zamfara</option>
    <option value="FCT">Federal Capital Territory (FCT)</option>
  </Form.Control>
</Form.Group>





  <Form.Group controlId="formHowDidYouHear">
    <Form.Label style={{fontSize: '16px', fontWeight: 'bold'}}>How Did You Hear About Us?</Form.Label>
    <Form.Control as="select" name="howDidYouHear" required style={{padding: '10px', borderRadius: '5px'}}>
      <option value="Facebook">Facebook</option>
      <option value="TikTok">TikTok</option>
      <option value="X">X</option>
      <option value="YouTube">YouTube</option>
      <option value="WhatsApp">WhatsApp</option>
      <option value="Friend">A Friend</option>
    </Form.Control>
  </Form.Group>

<br />

  <Form.Group controlId="formTerms">
    <Form.Check type="checkbox" label="I agree to the terms and conditions" required style={{fontSize: '14px'}} />
  </Form.Group>
  <br />
  {responseMessage && <p className="text-success mt-2" style={{fontSize: '14px'}}>{responseMessage}</p>}
  {errorMessage && <p className="text-danger mt-2" style={{fontSize: '14px'}}>{errorMessage}</p>}
  <Button variant="primary" type="submit" disabled={loading} style={{backgroundColor: `${backgroundColor}`, color: `${textColor}`, border: 'none', padding: '10px 20px', borderRadius: '20px', fontSize: '16px'}}>
    {loading ? 'Loading...' : 'Sign Up'}
  </Button>


</Form>
          )}
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: `${backgroundColor}`, color: `${textColor}`}}>
          {modalType === 'signIn' && (
<Button variant="link" onClick={handleShowForgotPasswordModal} style={{fontSize: '14px', backgroundColor: `${backgroundColor}`, color: `${textColor}`}}>Forgot Password?</Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal show={showForgotPasswordModal} onHide={handleCloseForgotPasswordModal} size="sm" centered style={{borderRadius: '10px'}}>
        <Modal.Header closeButton style={{backgroundColor: `${backgroundColor}`, color: `${textColor}`}}>
          <Modal.Title style={{fontSize: '20px', fontWeight: 'bold'}}>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => handleSubmit(e, 'forgot-password')}>
<Form.Group controlId="formEmail">
  <Form.Label style={{fontSize: '16px', fontWeight: 'bold'}}>Email</Form.Label>
  <Form.Control type="email" name="email" placeholder="Enter your email" required style={{padding: '10px', borderRadius: '5px'}} />
</Form.Group>
<Button variant="primary" type="submit" disabled={loading} style={{backgroundColor: `${backgroundColor}`, color: `${textColor}`, border: 'none', padding: '10px 20px', borderRadius: '20px', fontSize: '16px', width: '100%'}}>
  {loading ? 'Loading...' : 'Submit'}
</Button>
{responseMessage && <p className="text-success mt-2" style={{fontSize: '14px'}}>{responseMessage}</p>}
{errorMessage && <p className="text-danger mt-2" style={{fontSize: '14px'}}>{errorMessage}</p>}
          </Form>
        </Modal.Body>
      </Modal>

      <Footer />

      <div style={{
        position: 'fixed',
        bottom: '20px',
        left: '20px',
        backgroundColor: '#25D366',
        borderRadius: '50%',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        animation: 'bounce 10s infinite',
      }}>
        <a href={`https://wa.me/${whatsappNumber}`} target="_blank" rel="noopener noreferrer">
        <FaWhatsapp size={40} color="#fff" />
        </a>
      </div>
      <style>
      {`
          @keyframes bounce {
            0%, 100% {
              transform: translateY(0);
              animation-timing-function: ease-in-out;
            }
            
            50% {
              transform: translateY(-20px);
              animation-timing-function: ease-in-out;
            }
          }

          .floating-icon {
            animation: bounce 4s infinite;
          }
        `}
      </style>


  <br /> <br />
    </div>

  );
}



export default App;