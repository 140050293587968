import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navbar, Nav, Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { FaMoneyBillAlt } from 'react-icons/fa';
import Footer from '../components/Footer';
import Swal from 'sweetalert2';
import Loading from '../components/Loading'; 

const DataPage = () => {
  const navigate = useNavigate();
  const [networkName, setNetworkName] = useState('');
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('userToken');

    if (!token) {
      navigate('/');
    } else {
      validateToken(token);
    }
  }, [navigate]);


  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
  
    const network = formData.get('network');
    const transactionPin = formData.get('transactionPin');
    const examqty = formData.get('examqty');
  
    if (!network || !examqty || !transactionPin) {
      setFormError('Please fill in all required fields.');
      return;
    }
  
    if (transactionPin !== data.pin) {
      Swal.fire({
        icon: 'error',
        title: 'Failed!',
        text: 'Invalid transaction pin',
      });
      return;
    }
  
    setLoading(true);
  
    try {
      const domain = window.location.hostname;
      const token = localStorage.getItem('userToken');
  
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/app/buyExam`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          network,
          examqty,
          transactionPin,
          domain,
        }),
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        Swal.fire({
          icon: 'error',
          title: 'Failed!',
          text: data.message || 'Error placing order, please try again',
        });
        return;
      }
  
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: data.message,
      }).then(() => {
        refreshUserInfo();
      });
  
    } catch (error) {
      console.error('Error placing order:', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed!',
        text: 'Error placing order, please try again',
      });
    } finally {
      setLoading(false);
    }
  };
  

  const validateToken = async (token) => {
    const domain = window.location.hostname;

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/app/userInfo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Add Authorization header
        },
        body: JSON.stringify({ token, domain }),
      });

      if (!response.ok) {
        throw new Error('Failed to validate token');
      }

      const responseData = await response.json();

      if (responseData.status === 'failed') {
        localStorage.removeItem('userToken');
        navigate('/');
      } else {
        setData(responseData.user); // Set user data to state
        setIsLoading(false);
      }
    } catch (error) {
      localStorage.removeItem('userToken');
      navigate('/'); // Redirect to login on error
    }
  };

  const handleBackClick = () => {
    navigate('/dashboard');
  };

  const handleCardClick = (path) => {
    navigate(path);
  };
  
  const refreshUserInfo = async () => {
    const token = localStorage.getItem('userToken');
    if (!token) return;

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/app/userInfo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Add Authorization header
        },
        body: JSON.stringify({ token, domain: window.location.hostname }),
      });

      if (!response.ok) {
        throw new Error('Failed to refresh user info');
      }

      const responseData = await response.json();

      if (responseData.status === 'success') {
        setUserData(responseData.user);
        setData(responseData.user);
      }

    } catch (error) {
      console.error('Error refreshing user info:', error);
    }
  };
  
  if (isLoading) {
    return <Loading />;
  }

  return (
    <div style={{ backgroundColor: '#F9FAFB', minHeight: '100vh' }}>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand onClick={handleBackClick} style={{ cursor: 'pointer' }}>
            {'Back'}
          </Navbar.Brand>
          <Nav className="ml-auto">
            <Nav.Link>Buy Result Pin</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <Container className="mt-4">
        <Row className="justify-content-center">
          <Col md={7}>
            <Card className="balance-card mb-4">
              <Card.Body className="d-flex align-items-center">
                <FaMoneyBillAlt className="menu-icon" />
                <div className="leftshift">
                  <h5>Wallet Balance</h5>
                  <div><b>₦{data && data.wallet && data.wallet.toLocaleString()}</b></div>
                </div>
              </Card.Body>
            </Card>

            <Card style={{ backgroundColor: 'white', borderRadius: '10px', border: 'none' }}>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="formNetwork">
                    <Form.Label>Exam Name</Form.Label>
                    <Form.Control as="select" name="network" required>
                      <option value="">Select</option>
                      <option value="WAEC">WAEC</option>
                        <option value="NECO">NECO</option>
                        <option value="NABTEB">NABTEB</option>

                    </Form.Control>
                  </Form.Group>

        

                  <Form.Group controlId="formAirtimeAmount">
                    <Form.Label>Quantity</Form.Label>
                    <Form.Control
                      type="number"
                      name="examqty"
                      placeholder="1"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="formTransactionPin">
                    <Form.Label>Transaction Pin</Form.Label>
                    <Form.Control type="password" name="transactionPin" placeholder="****" required />
                  </Form.Group><br />

                  <Button type="submit" variant="primary" disabled={loading}>
                    {loading ? 'Placing Order...' : 'Place Order'}
                  </Button>

                  {formError && <div style={{ color: 'red', marginTop: '10px' }}>{formError}</div>}
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default DataPage;
