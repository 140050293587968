import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navbar, Nav, Container, Row, Col, Card, Table, Button } from 'react-bootstrap';
import Loading from '../components/Loading';
import Footer from '../components/Footer'; // Import Footer component

const PricingPage = () => {
  const navigate = useNavigate();
  const [pricingData, setPricingData] = useState([]); // State for pricing data
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [transactionsPerPage] = useState(5);
  const [visiblePages, setVisiblePages] = useState([1, 2, 3, 4, 5]);
  const domain = window.location.hostname;

  // CSS Styles (Customize these as needed)
  const cardStyle = {
    backgroundColor: 'white',
    borderRadius: '10px',
    border: 'none',
    padding: '20px',
  };

  const cardTitleStyle = {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginBottom: '10px',
  };

  const cardTextStyle = {
    fontSize: '1rem',
  };

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (!token) {
      navigate('/');
    } else {
      fetchPricingData(token, domain);
    }
  }, [navigate]);

  const fetchPricingData = async (token, domain) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/siteDataPlans`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Domain': domain // Pass domain as a header

        },
        body: JSON.stringify({ token, domain }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch pricing data');
      }

      const data = await response.json();
      setPricingData(data.siteData); // Access siteData from response

      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching pricing data:', error);
      // Handle error appropriately (e.g., display a message)
    }
  };

  // Pagination logic
  const indexOfLastTransaction = currentPage * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
  const currentTransactions = pricingData.slice(indexOfFirstTransaction, indexOfLastTransaction);

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);

    const totalPages = Math.ceil(pricingData.length / transactionsPerPage);
    const start = Math.max(1, pageNumber - 2);
    const end = Math.min(totalPages, pageNumber + 2);
    setVisiblePages(Array.from({ length: end - start + 1 }, (_, i) => i + start));
  };

  // Handle previous and next page clicks
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(pricingData.length / transactionsPerPage);
    if (currentPage < totalPages) {
      paginate(currentPage + 1);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div style={{ backgroundColor: '#F9FAFB', minHeight: '100vh' }}>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand onClick={() => navigate('/dashboard')} style={{ cursor: 'pointer' }}>
            Back
          </Navbar.Brand>
          <Nav className="ml-auto">
            <Nav.Link>Pricing</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <Container className="mt-4">
        <Row className="justify-content-center">
          <Col md={12}>
            <div>
              {pricingData.length > 0 ? (
                <div style={cardStyle}>
                  <div>
                    <center>  <strong>{domain.toUpperCase()} PRICING</strong> </center> <hr />
                    <Table responsive>

                      <tbody>
                        {/* Sort the pricingData by network in descending order, dataSize ASC, but 500 first */}
                        {currentTransactions.sort((a, b) => {
                          // Priority 1: Sort by dataSize, 500 first, then 1, 2, 3...
                          if (a.dataSize === '500' && b.dataSize !== '500') {
                            return -1;
                          } else if (a.dataSize !== '500' && b.dataSize === '500') {
                            return 1;
                          } else if (a.dataSize !== '500' && b.dataSize !== '500') {
                            if (parseInt(a.dataSize) < parseInt(b.dataSize)) {
                              return -1;
                            } else if (parseInt(a.dataSize) > parseInt(b.dataSize)) {
                              return 1;
                            }
                          }

                          // Priority 2: Sort by network in descending order
                          if (b.network < a.network) {
                            return 1;
                          } else if (b.network > a.network) {
                            return -1;
                          } else {
                            return 0;
                          }
                        }).map((plan, index) => (
                          <tr key={index}>
                            <td style={cardTextStyle} width="50%">{plan.dataSize} {plan.sizeType}<br /><small>{plan.network} {plan.planType === 'CGD' ? 'CORPORATE GIFTING' : plan.planType}</small></td>
                            <td style={cardTextStyle}><small>Enduser: ₦{plan.endUserAmount}/GB<br />
                            Agent: ₦{plan.AgentAmount}/GB<br />
                            Reseller: ₦{plan.resellerAmount}/GB<br />
                              Websites: ₦{plan.webOwnerAmount}/GB             
                            </small>
                            </td>
                 
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    <div className="d-flex justify-content-center mt-3">
                      <Button variant="outline-secondary" disabled={currentPage === 1} onClick={handlePreviousPage}>
                        Previous
                      </Button>
                      {/* Map through visible pages and render pagination buttons */}
                      {visiblePages.map((pageNumber) => (
                        <Button variant={pageNumber === currentPage ? 'primary' : 'outline-secondary'} key={pageNumber} onClick={() => paginate(pageNumber)}>
                          {pageNumber}
                        </Button>
                      ))}
                      <Button variant="outline-secondary" disabled={currentPage === Math.ceil(pricingData.length / transactionsPerPage)} onClick={handleNextPage}>
                        Next
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <p>Loading pricing data...</p>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />  {/* Add the Footer component */}
    </div>
  );
};

export default PricingPage;