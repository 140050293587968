// BulkSMS.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navbar, Nav, Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { FaMoneyBillAlt } from 'react-icons/fa';
import { useAuth } from '../utils/auth';
import Footer from '../components/Footer';
import Loading from '../components/Loading';
import Swal from 'sweetalert2';

const BulkSMS = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [message, setMessage] = useState('');
  const [sender, setSender] = useState('');
  const [recipients, setRecipients] = useState('');
  const [transactionPin, setTransactionPin] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false); // For loading animation

  useAuth();

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (!token) {
      navigate('/');
    } else {
      validateToken(token);
    }
  }, [navigate]);

  const handleBackClick = () => {
    navigate('/dashboard');
  };

  const validateToken = async (token) => {
    const domain = window.location.hostname;
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/app/userInfo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Add Authorization header
        },
        body: JSON.stringify({ token, domain }),
      });
      if (!response.ok) {
        throw new Error('Failed to validate token');
      }
      const responseData = await response.json();
      if (responseData.status === 'failed') {
        localStorage.removeItem('userToken');
        navigate('/');
      } else {
        setData(responseData.user);
        setIsLoading(false);
      }
    } catch (error) {
      localStorage.removeItem('userToken');
      navigate('/');
    }
  };

  const handleSendMessage = async () => {
    if (!sender || !recipients || !message || !transactionPin) {
      Swal.fire({ icon: 'error', title: 'Failed!', text: 'All fields are required.' });
      return;
    }

    if (transactionPin !== data.pin) {
      Swal.fire({ icon: 'error', title: 'Failed!', text: 'Invalid transaction pin' });
      return;
    }

    setIsSubmitting(true); // Show loading animation

    const token = localStorage.getItem('userToken');
    const domain = window.location.hostname;

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/app/BulkSms`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Pass the token as a Bearer token
        },
        body: JSON.stringify({
          sender,
          recipients,
          message,
          transactionPin,
          domain
        }),
      });

      const responseData = await response.json();
      if (responseData.status === 'success') {
        Swal.fire({ icon: 'success', title: 'Success!', text: responseData.message });
        // Clear form fields or redirect as needed
        setSender('');
        setRecipients('');
        setMessage('');
        setTransactionPin('');
      } else {
        Swal.fire({ icon: 'error', title: 'Failed!', text: responseData.message });
      }
    } catch (error) {
      Swal.fire({ icon: 'error', title: 'Error!', text: 'An error occurred. Please try again later.' });
    } finally {
      setIsSubmitting(false); // Hide loading animation
    }
  };

  const handleMessageChange = (e) => {
    if (e.target.value.length <= 160) {
      setMessage(e.target.value);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div style={{ backgroundColor: '#F9FAFB', minHeight: '100vh' }}>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand onClick={handleBackClick} style={{ cursor: 'pointer' }}>
            {'Back'}
          </Navbar.Brand>
          <Nav className="ml-auto">
            <Nav.Link>Bulk SMS</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <Container className="mt-4">
        <Row className="justify-content-center">
          <Col md={7}>
            <Card className="balance-card mb-4">
              <Card.Body className="d-flex align-items-center">
                <FaMoneyBillAlt className="menu-icon" />
                <div className="leftshift">
                  <h5>Wallet Balance</h5>
                  <div><b>₦{data && data.wallet && data.wallet.toLocaleString()}</b></div>
                </div>
              </Card.Body>
            </Card>

            <Card className="balance-card mb-4 mb-4">
              <Card.Body>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>From (Sender)</Form.Label>
                    <Form.Control
                      type="text"
                      value={sender}
                      onChange={(e) => setSender(e.target.value)}
                      placeholder="Enter sender name or number"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Recipients</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={recipients}
                      onChange={(e) => setRecipients(e.target.value)}
                      placeholder="Enter recipients (e.g., 08012345678, 23480123456)"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      maxLength={160}
                      value={message}
                      onChange={handleMessageChange}
                      placeholder="Enter your message (max 160 characters)"
                    />
                    <Form.Text className="text-muted">
                      {message.length}/160 characters
                    </Form.Text>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Transaction Pin</Form.Label>
                    <Form.Control
                      type="password"
                      value={transactionPin}
                      onChange={(e) => setTransactionPin(e.target.value)}
                      placeholder="Enter transaction pin"
                    />
                  </Form.Group>
                  <Button
                    variant="primary"
                    onClick={handleSendMessage}
                    disabled={isSubmitting} // Disable button while submitting
                  >
                    {isSubmitting ? 'Sending...' : 'Send Message'} {/* Show loading state */}
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default BulkSMS;
