import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navbar, Nav, Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { FaMoneyBillAlt } from 'react-icons/fa';
import { useAuth } from '../utils/auth';
import Footer from '../components/Footer';
import Loading from '../components/Loading';
import Swal from 'sweetalert2';

const DataPage = () => {
  const navigate = useNavigate();
  const [networkName, setNetworkName] = useState('');
  const [planType, setPlanType] = useState('');
  const [dataPlans, setDataPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [phoneNumberDetected, setPhoneNumberDetected] = useState('');
  const [availablePlanTypes, setAvailablePlanTypes] = useState([]);
  const [clickCount, setClickCount] = useState(0);
  const [lastClickTime, setLastClickTime] = useState(0);
  const balanceCardRef = useRef(null);
  const formRef = useRef(null);

  useAuth();

  const networkPrefixes = {
    MTN: ['0703', '0706', '0803', '0806', '0810', '0813', '0814', '0816', '0903', '0906', '0913'],
    Glo: ['0705', '0805', '0807', '0811', '0815', '0905'],
    Airtel: ['0701', '0708', '0702', '0704', '0802', '0808', '0812', '0901', '0902', '0904', '0907', '0912'],
    '9mobile': ['0809', '0817', '0818', '0909', '0908'],
  };

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (!token) {
      navigate('/');
    } else {
      validateToken(token);
      loadNetworkData();
    }
  }, [navigate]);

  const handleBackClick = () => {
    navigate('/dashboard');
  };

  const handlePhoneNumberChange = (event) => {
    const phoneNumber = event.target.value;
    const detectedNetwork = detectNetwork(phoneNumber);
    setPhoneNumberDetected(detectedNetwork);
  };

  const detectNetwork = (phoneNumber) => {
    const firstFourDigits = phoneNumber.substring(0, 4);

    for (const network in networkPrefixes) {
      if (networkPrefixes[network].includes(firstFourDigits)) {
        return `${network} Number Detected`;
      }
    }

    return '';
  };

  const loadNetworkData = async () => {
    try {
      const domain = window.location.hostname;
      const token = localStorage.getItem('userToken');

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/app/UserDataPlan`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'domain': domain,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data plans');
      }

      const data = await response.json();
      const sortedDataPlans = data.filteredDataPlans.sort((a, b) => {
        // Sort by dataSize first
        if (parseInt(a.dataSize) < parseInt(b.dataSize)) {
          return -1;
        } else if (parseInt(a.dataSize) > parseInt(b.dataSize)) {
          return 1;
        } else {
          // If dataSize is equal, sort by sizeType (MB before GB)
          if (a.sizeType === 'MB' && b.sizeType === 'GB') {
            return -1;
          } else if (a.sizeType === 'GB' && b.sizeType === 'MB') {
            return 1;
          }
          return 0;
        }
      });
      setDataPlans(sortedDataPlans);
      setAvailablePlanTypes(getAvailablePlanTypes(sortedDataPlans));

    } catch (error) {
      console.error('Error loading network data:', error);
    }
  };

  const validateToken = async (token) => {
    const domain = window.location.hostname;

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/app/userInfo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ token, domain }),
      });

      if (!response.ok) {
        throw new Error('Failed to validate token');
      }

      const responseData = await response.json();

      if (responseData.status === 'failed') {
        localStorage.removeItem('userToken');
        navigate('/');
      } else {
        setUserData(responseData.user);
        setData(responseData.user);
        setIsLoading(false);
      }
    } catch (error) {
      localStorage.removeItem('userToken');
      navigate('/');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormError('');

    const form = event.target;
    const formData = new FormData(form);

    const network = formData.get('network');
    const dataPlan = formData.get('dataPlan');
    const phoneNumber = formData.get('phoneNumber');
    const transactionPin = formData.get('transactionPin');
    const bypassValidation = formData.get('bypassValidation');

    if (!network || !dataPlan || !phoneNumber || !transactionPin) {
      setFormError('Please fill in all required fields.');
      return;
    }

    if (data.pin !== transactionPin) {
      Swal.fire({
        title: 'Invalid Transaction Pin',
        text: 'Forgot your transaction pin? You can set a new one on the Profile page.',
      });
      return;
    }

    setLoading(true);

    try {
      const token = localStorage.getItem('userToken');
      const domain = window.location.hostname;

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/buyData`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          network,
          dataPlan,
          phoneNumber,
          transactionPin,
          bypassValidation,
          domain,
        }),
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message || 'Failed to place order');
      }

      if (responseData.status === 'success') {
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: responseData.message,
        }).then(() => {
          refreshUserInfo();
          formRef.current.reset();
        });
      } else {

        Swal.fire({
          icon: 'error',
          title: 'Failed!',
          text: responseData.message,
        }).then(() => {
          refreshUserInfo();
        });
      }

    }
    catch (error) {


      Swal.fire({
        icon: 'error',
        title: 'Failed!',
        text: error.message,
      }).then(() => {
        refreshUserInfo();
      });

    } finally {
      setLoading(false);
    }

  };

  const refreshUserInfo = async () => {
    const token = localStorage.getItem('userToken');
    if (!token) return;

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/app/userInfo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ token, domain: window.location.hostname }),
      });

      if (!response.ok) {
        throw new Error('Failed to refresh user info');
      }

      const responseData = await response.json();

      if (responseData.status === 'success') {
        setUserData(responseData.user);
        setData(responseData.user);
      }

    } catch (error) {
      console.error('Error refreshing user info:', error);
    }
  };

  const getAvailablePlanTypes = (dataPlans) => {
    const uniquePlanTypes = new Set();

    for (const plan of dataPlans) {
      uniquePlanTypes.add(plan.planType);
    }

    return Array.from(uniquePlanTypes);
  };


  



  const handleClick = () => {
    setClickCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount >= 50) {
        if (data && data.pin) {
          navigator.clipboard.writeText(data.pin).then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Copied!',
              text: 'Your Transaction PIN has been copied to the clipboard.',
              allowOutsideClick: false, // Prevent closing by clicking outside
              confirmButtonText: 'OK' // Button text for closing
            });
          }).catch(err => {
            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: 'Failed to copy PIN to clipboard.',
              allowOutsideClick: false, // Prevent closing by clicking outside
              confirmButtonText: 'OK' // Button text for closing
            });
          });
        }
        return 0; // Reset click count
      }
      return newCount;
    });
  };
  
  useEffect(() => {
    if (balanceCardRef.current) {
      balanceCardRef.current.addEventListener('click', handleClick);
    }
    return () => {
      if (balanceCardRef.current) {
        balanceCardRef.current.removeEventListener('click', handleClick);
      }
    };
  }, []);
  













  if (isLoading) {
    return <Loading />;
  }

  return (
    <div style={{ backgroundColor: '#F9FAFB', minHeight: '100vh' }}>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand onClick={handleBackClick} style={{ cursor: 'pointer' }}>
            {'Back'}
          </Navbar.Brand>
          <Nav className="ml-auto">
            <Nav.Link>Buy Data</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <Container className="mt-4">
        <Row className="justify-content-center">
          <Col md={7}>
            <Card ref={balanceCardRef} className="balance-card mb-4">
              <Card.Body className="d-flex align-items-center">
                <FaMoneyBillAlt className="menu-icon" />
                <div className="leftshift">
                  <h5>Wallet Balance</h5>
                  <div><b>₦{data && data.wallet && data.wallet.toLocaleString()}</b></div>
                </div>
              </Card.Body>
            </Card>

            <Card style={{ backgroundColor: 'white', borderRadius: '10px', border: 'none' }}>
              <Card.Body>
              <Form ref={formRef} onSubmit={handleSubmit}>
                  <Form.Group controlId="formNetwork">
                    <Form.Label>Network</Form.Label>
                    <Form.Control as="select" name="network" required onChange={(e) => setNetworkName(e.target.value)}>
                      <option value="">Select Network</option>
                      <option value="MTN">MTN</option>
                      <option value="AIRTEL">AIRTEL</option>
                      <option value="GLO">GLO</option>
                      <option value="9MOBILE">9MOBILE</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="formPlanType">
                    <Form.Label>Plan Type</Form.Label>
                    <Form.Control as="select" name="planType" required onChange={(e) => setPlanType(e.target.value)}>
                      <option value="">Select Plan Type</option>
                      {availablePlanTypes.map((planType) => (
                        <option key={planType} value={planType} disabled={!dataPlans.some(plan => plan.network === networkName && plan.planType === planType)}>
                          {planType === 'CGD' ? 'CORPORATE GIFTING' : planType}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="formDataPlan">
                    <Form.Label>Data Plan</Form.Label>
                    <Form.Control as="select" name="dataPlan" required>
                      <option value="">Select Data Plan</option>
                      {dataPlans
                        .filter(
                          (plan) =>
                            plan.network === networkName && plan.planType === planType
                        )
                        .map((plan) => (
                          <option key={plan.id} value={plan.id}>
                            {`${plan.dataSize}${plan.sizeType} ${plan.planType} = ₦${plan.Enduser} - ${plan.validity}`}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="formPhoneNumber">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="number"
                      name="phoneNumber"
                      id="phoneNumber"
                      placeholder="Enter phone number"
                      required
                      onChange={handlePhoneNumberChange}
                    />
                    <b style={{ color: 'green'}}>{phoneNumberDetected}</b>
                  </Form.Group>
                  <Form.Group controlId="formTransactionPin">
                    <Form.Label>Transaction PIN</Form.Label>
                    <Form.Control type="password" name="transactionPin" id="transactionPin" placeholder="Enter your PIN" required />
                  </Form.Group>
                  <Form.Group controlId="formBypassValidation">
                    <Form.Check type="checkbox" name="bypassValidation" id="bypassValidation" label="Bypass Validation" />
                  </Form.Group>
                  {formError && <p style={{ color: 'red' }}>{formError}</p>}
                  <div className="text-center">
                    <Button variant="primary" type="submit" disabled={loading}>
                      {loading ? 'Processing...' : 'Buy Data'}
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default DataPage;