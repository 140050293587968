import React from 'react';

function Footer() {
  const footerStyle = {
    bottom: '0',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    color: '#ffffff',
    textAlign: 'center',
    padding: '10px 0',
    fontSize: '14px',
  };

  const linkStyle = {
    color: '#ffffff',
    textDecoration: 'none',
  };

  const currentYear = new Date().getFullYear();

  return (
    <footer style={footerStyle}>
      <p>© {currentYear} | Developed By <a href="https://wa.me/2349014532386" target="_blank" rel="noopener noreferrer" style={linkStyle}>Positive Developers</a></p>
    </footer>
  );
}

export default Footer;